'use strict';

var $ = require('jquery');

// var LOCATION = {lat: 50.054485, lng: 19.947700};
var LOCATION = window.Hotellocation;
var ICON = window.mapMarker;

var googleMap = function (name) {

  var mapContainer = $('.google-map');

  window.onMapsLoad(function() {
    if(mapContainer) {
      var google = window.google;
      var map = new google.maps.Map(mapContainer[0], {
        center: LOCATION,
        scrollwheel: false,
        zoom: 15,
      });
      var marker = new google.maps.Marker({
        map: map,
        position: LOCATION,
        icon : ICON
      }); 
    }

  })
};

module.exports = googleMap;
