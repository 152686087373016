'use strict';

var $ = require('jquery');

var nav = function (name) {
  var $header = $('header');
  var $menu = $header.find('nav');
  var top = 0;
  var $hamburger = $('.hamburger-menu');
  var $logo = $header.find('.top--logo-container');

  /* menu hamburger bar animation */
  $hamburger.on('click', function(){
    $(this).toggleClass('crossed');
    $logo.toggleClass('is-faded');

    if($(this).hasClass('crossed')) {
        $('html').css('overflow','hidden');
        $header.removeClass('is-filled');
      }
    else {
      $('html').css('overflow','scroll');
    }
    
    $('.overlay').toggleClass('is-hidden');
    $menu.toggleClass('is-hidden');

    topbarSwitcher();
  });

  /* top menu bar background */
  window.addEventListener('scroll', function(e){ topbarSwitcher(); });


  function topbarSwitcher (){
    // top = window.scrollY;
    top = $(window).scrollTop();
    if (top > 30 ) {
      if ( !( $header.hasClass( 'is-filled' ) ) && ($('.overlay').hasClass('is-hidden')) ) {
        $header.addClass('is-filled');
      }
    }
    else if ( ! ($('.hamburger-menu').hasClass('crossed') ) ) {
      $header.removeClass('is-filled');
    }
  };

  /* smooth menu scroll */

  $('nav li a').on('click', function(e){
    e.preventDefault();
    var wantedSection = '.'+$(this).attr('href')+'-section';
    var $scrollTo = $(wantedSection).offset().top;
    $('html, body').animate({scrollTop: $scrollTo - 90}, 500);

    if ($(window).width() < 1024) {
      $menu.toggleClass('is-hidden');
      $hamburger.toggleClass('crossed');
      $('.overlay').toggleClass('is-hidden');
      $('html').css('overflow','scroll');
      $logo.toggleClass('is-faded');
    }

  });
};

module.exports = nav;
