'use strict';

var $ = require('jquery');

var career = function (name) {
  var $articles = $('.popup').find('article'); // hidden articles
  var atr, $article, isActive;
  var $header = $('header');
  var $overlay = $('.overlay');

  isActive = false;
  /* menu hamburger bar animation */
  $('.close').on('click', function(){
    $(this).parent().addClass('is-hidden').removeClass('is-active');
    $header.css('z-index','10');
    $('html').css('overflow','scroll');
    $overlay.toggleClass('is-hidden');
  });

  $('.option--item').on('click', function(){
    var $clicked = $(this).attr('data-item');
    for (var i=0; i<$articles.length; i++) {
      atr = $($articles[i]).attr('data-item');
      if (atr == $clicked) {
        $header.css('z-index','9');
        $('html').css('overflow','hidden');
        // closePopup($($articles[i]));
        $article = $($articles[i]);
        $overlay.toggleClass('is-hidden');
        isActive = true;
        $article.removeClass('is-hidden').addClass('is-active');
      }
    }
  });

};

module.exports = career;
