/*
  Project: Eagle Seven New
  Author: Xfive
 */

var greet = require('./greeting.js');
var nav = require('./nav.js');
var carousel = require('./carousel');
var career = require('./career.js');
var googleMap = require('./map.js');
var fileinputs = require('./inputs.js');

greet('World');
nav();
career();
googleMap();
carousel();
fileinputs(document, window, 0);