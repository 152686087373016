'use strict';

var $ = window.jQuery = require('jquery');

// require ('./slick/slick');

var carousel = function () {

  // $('.carousel').slick({
  //   arrows: false,
  //   autoplay: true,
  //   autoplaySpeed: 4000,
  //   centerMode: true,
  //   dots: false,
  //   infinite: true,
  //   mobileFirst: true,
  //   easing: 'ease',
  //   slidesToScroll: 1,
  //   slidesToShow: 3,
  //   vertical: true,
  //   verticalSwiping: true,
  //   responsive: [
  //     {
  //       breakpoint: 600,
  //         settings: {
  //         slidesToShow: 3,
  //       }
  //     }
  //   ]
  // });

  var quotes = $('.carousel-title');
  var quoteIndex = -1;

  function showNextQuote() {
    ++quoteIndex;
    setTimeout(function(){
      quotes.eq(quoteIndex % quotes.length).toggleClass('active');
    }, 2000);
    setTimeout(function(){
      quotes.eq(quoteIndex % quotes.length).toggleClass('active');
    }, 7000);
    setTimeout(function(){
      showNextQuote();
    },8000);
  };

  showNextQuote();

}

module.exports = carousel; 